import { EnumTpEspecie } from "model/enums/enum-tp-especie";

export class ParametrizacaoBoletoModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public empresaId: string = '',
        public contaBancariaId: string = '',
        public descricaoConfiguracao: string = '',
        public codigoBeneficiario: string = '',
        public carteira: number = 0,
        public ultimoNossoNumero: string = '',
        public registroOnline: boolean = true,
        public layoutArquivoRetorno: '240' | '300' = '240',
        public especie: EnumTpEspecie = EnumTpEspecie.DuplicataMercantil,
        public percJuros: number = 0,
        public percMulta: number = 0,
        public protesto: boolean = true,
        public numDiasProtesto: number = 0,
        public percDesconto1: number = 0,
        public numDiasAntesVencimentoDesconto1: number = 0,
        public percDesconto2: number = 0,
        public numDiasAntesVencimentoDesconto2: number = 0,
        public percDesconto3: number = 0,
        public numDiasAntesVencimentoDesconto3: number = 0,
        public mensagemRecibo: string = '',
        public mensagemFichaCompensacao: string = '',
        public boletoPix: boolean = false,
    ) { }
}